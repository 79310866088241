export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, viewport-fit=contain"},{"charset":"utf-8"},{"name":"referrer","content":"origin-when-cross-origin"},{"name":"apple-mobile-web-app-title","content":"Pondera Group"},{"name":"application-name","content":"Pondera Group"},{"name":"google-site-verification","content":"c3_P5Jnl3H1I_6evHxAJLPOKlzop_IPTzBlGA3Pu2x8"},{"name":"msapplication-TileColor","content":"#ffffff"},{"name":"theme-color","content":"#121212"}],"link":[{"sizes":"180x180","rel":"apple-touch-icon","href":"/favicons/development/apple-touch-icon.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicons/development/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favicons/development/favicon-16x16.png"},{"rel":"mask-icon","color":"#5bbad5","href":"/favicons/development/safari-pinned-tab.svg"},{"rel":"manifest","href":"/favicons/development/site.webmanifest"}],"style":[],"script":[],"noscript":[],"title":"Pondera Group","charset":"utf-8","viewport":"width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, viewport-fit=contain","htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false